

	
	function setCookie(name, value, days) {
    var expires = "";
    if (days) {
        var date = new Date();
        date.setTime(date.getTime() + (days*24*60*60*1000));
        expires = "; expires=" + date.toUTCString();
    }
    document.cookie = name + "=" + (value || "")  + expires + "; path=/";
}
function getCookie(name) {
    var nameEQ = name + "=";
    var ca = document.cookie.split(';');
    for(var i=0;i < ca.length;i++) {
        var c = ca[i];
        while (c.charAt(0)==' ') c = c.substring(1,c.length);
        if (c.indexOf(nameEQ) == 0) return c.substring(nameEQ.length,c.length);
    }
    return null;
}

document.addEventListener("DOMContentLoaded", function() {
	console.log('%c SCRUX.IO! 0.8A', 'font-weight: bold; font-size: 50px;color: white; text-shadow: 3px 3px 0 rgb(217,31,38) , 6px 6px 0 rgb(226,91,14) , 9px 9px 0 rgb(245,221,8) , 12px 12px 0 rgb(5,148,68) , 15px 15px 0 rgb(2,135,206) , 18px 18px 0 rgb(4,77,145) , 21px 21px 0 rgb(42,21,113)');
    console.log("DOM fully loaded and parsed");
	
    let userId = getCookie('userId');
    let userPicture = getCookie('profilePic');
    let userName = getCookie('firstName');
    if (userId) {
        let profilePanel = document.getElementById('profilePanel');
        let loginContainer = document.getElementById('loginContainer');
        let guestProfilePanel = document.getElementById('guestProfilePanel');
        let ProfileUsername = document.getElementById('ProfileUsername');
        let userProfilePic = document.getElementById('userProfilePic');
        profilePanel.style.display = 'block';
        loginContainer.style.display = 'none';
        guestProfilePanel.style.display = 'none';
        ProfileUsername.textContent = userName;
        userProfilePic.src = userPicture;
    }
});



	function handleCredentialResponse(response) {

	
		fetch('https://scrux-backend.herokuapp.com/api/auth/google', {
		method: 'POST',
		headers: {
      'Content-Type': 'application/json'
    },
		body: JSON.stringify({ token: response.credential })
	})
		.then(response => {
    if (!response.ok) {
      throw new Error('Error occurred while authenticating');
    }
    return response.json(); // Parse the response as JSON
  })
  .then(data => {
    console.log(data);
    document.getElementById('profilePanel').style.display = 'block';
    document.getElementById('loginContainer').style.display = 'none';
    document.getElementById('guestProfilePanel').style.display = 'none';
            
    const fullName = data.name;
    const firstName = fullName.split(' ')[0];
    document.getElementById('ProfileUsername').textContent = firstName;
	let userId = data.userId;
	setCookie('userId', userId, 1);
	setCookie('firstName', firstName, 1000);


    // Set the profile picture
    const profilePic = document.getElementById('userProfilePic');
    profilePic.src = data.picture;
	setCookie('profilePic', profilePic.src, 1000);
  })
  .catch(error => {
    console.error(error);
    // Authentication failed
  });
}